.post-comments-drawer {
	display: flex;
	flex-direction: column;
    position: absolute;
    left: 0px;
	bottom: -100%;
    overflow: hidden;
    width: 100%;
	height: 100%;
    background-color: rgba(var(--background-color-secondary-rgb), 0.97);
	backdrop-filter: blur(10px);
	padding: 20px;
	border-radius: 15px;
	z-index: 3;
	transition-duration: 0.3s;
}

.post-preview-cont.extended .post-comments-drawer {
	position: fixed;
}

.post-comments-drawer.open {
	bottom: 0%;
	transition-duration: 0.6s;
}

.post-comments-wrapper {
	height: calc(100% - 40px);
}

.comments-cont {
	overflow: auto;
}

.post-comments-drawer .write-comment-cont textarea {
    min-height: 35px;
    height: 35px;
    max-height: 200px;
    overflow: auto;
}

.comment {
	background-color: var(--background-color);
	border-radius: 6px;
	margin-bottom: 10px;
	padding: 5px;
	font-size: 0.75rem;
}

div.comment-list-author-preview .user-image {
	width: 25px;
}
.write-comment-cont .send-btn {
	width: fit-content;
	height: 35px;
}
