.page-cont {
	padding: 3vh 3%;
	height: 100%;
	width: 100%;
    display: flex;
    flex-direction: column;
	position: relative;
	/* overflow: hidden; */
}

.page-cont > * {
	height: 100%;
}

.page-cont .page-cont {
	padding: 0;
}

.page-headbar {
	margin-bottom: 20px;
	position: relative;
	width: 100%;
	z-index: 3;
}

.page-title {
	font-family: 'Poppins-Thin';
	font-size: 3rem;
	line-height: 2.4rem;
	text-transform: uppercase;
	color: var(--color-primary);
	margin-right: 10px;
	filter: brightness(var(--color-primary-brightness));
	margin-top: 0.1rem;
}
@media screen and (max-width: 700px) {
	.page-cont {
		padding: 0vh 3%;
		padding-top: 10px;
	}
}
