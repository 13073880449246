.site-logo {
	max-width: 33%;
	height: 100%;
	min-width: 50px;
}

.site-logo img {
	max-width: 100%;
	max-height: 100%;
}
