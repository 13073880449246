
.comment-date {
	font-size: 0.625rem;
	color: rgb(118, 118, 118);
}

.CommentMenu .comment-menu-items {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 2px;
}

.CommentMenu .comment-menu-items > div {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: grey;
	padding: 2px;
	border-radius: 3px;
}

.CommentMenu .comment-menu-items > div:not(:first-child)
{
	margin-left: 0.5rem;
}

.CommentMenu .comment-menu-items > div:hover {
	background-color: var(--background-color);
	color: var(--font-color);
}
