.DocUploader .drop-cont {
	position: relative;
	height: 100px;
	background-color: var(--background-color);
	border-radius: 5px;
}

.DocUploader .drop-cont .placeholder {
	pointer-events: none;
}

.DocUploader .drop-cont .file-input {
	position: absolute;
	width: 100%;
	height: 100%;
	opacity: 0;
	cursor: pointer;
}


.DocUploader .drop-cont.error {
	border: 2px solid red;
}

.DocUploader .DocPreview iframe {
	width: 100%;
	height: 40vh;
	border-radius: 6px;
	position: relative;
}

.DocUploader .DocPreview {
	position: relative;
}

.DocUploader .DocPreview .loading-frame {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    overflow: hidden;
	z-index: 1;
}

.DocUploader .DocPreview .document-tile {
	background-color: var(--background-color);
    margin: 0;
    width: 100%;
}

.DocUploader .DocPreview .too-heavy-msg {
	color: grey;
}
