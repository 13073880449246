.SitePreview{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
}

.SitePreview .map-cont {
	height: 33vh;
	min-height: 200px;
	z-index: 0;
}

.SitePreview .site-identity {
	/* height: 50%; */
	display: flex;
}

.SitePreview .site-identity .title {
	font-size: 2.5rem;
	line-height:  2.5rem;
}

.SitePreview .site-logo {
	max-width: 33%;
	max-height: 80%;
}

.SitePreview .location {
	line-height: 1rem;
	font-size: 1rem;
}

.SitePreview .site-contact-icon {
	color: grey;
	font-size: 1.25rem;
}
