.FormPreview {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	position: relative;
	border-radius: 12px;
}

.FormPreview .FormHeader {
	background-color: var(--background-color-secondary);
	padding: 12px;
	border-radius: 12px;
	border-top-right-radius: 6px;
}

.FormPreview .FormHeader .dropdown__control {
	background-color: var(--background-color);
}

.FormPreview .FormHeader .form-title {
	font-size: 1.9rem;
	line-height: 2.2rem;
}

.FormPreview .FormHeader .user-select-cont {
	transition-duration: 0.5s;
	background-color: var(--background-color-secondary);
}

.FormPreview .FormHeader.scrolled .user-select-cont {
	position: absolute;
	top: 0px;
	left: 0;
	width: 100%;
	background-color: var(--background-color-secondary);
	padding: 10px;
	transition-duration: 0s;
	z-index: 2;
	border-radius: 12px;
	box-shadow: 0px 0px 0px 4px var(--background-color);
	border: 2px solid rgba(var(--color-primary-rgb), 0.2);
}

.FormPreview .FormHeader .user-select-cont:not(.hasCurUser) {
	margin: 0 !important;
	width: 100% !important;
	padding-bottom: 0 !important;
}

.FormPreview .FormHeader .form-desc a {
	color: #0d6efd;
}

.FormPreview .form-infos {
	color: grey;
	font-size: 0.75rem;
	text-transform: uppercase;
}

/* Sections */

.FormPreview .SectionViewer .section-tag {
	text-transform: uppercase;
	font-size: 1rem;
	font-family: "Poppins-SemiBold";
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
	margin-left: -12px;
	width: calc(100% + 24px);
	margin-top: -12px;
	padding: 6px;
	padding-left: 12px;
}

.FormPreview .section-viewer-head {
	background-color: var(--background-color-secondary);
	padding: 12px;
	border-radius: 12px;
	overflow: hidden;
}

.FormPreview .section-viewer-title {
	font-size: 1.3rem;
	font-family: "Poppins-Bold";
}

.FormPreview .section-viewer-desc {
	font-size: 1rem;
}

/* Questions */

.FormPreview .QuestionViewer {
	background-color: var(--background-color-secondary);
	padding: 12px;
	border-radius: 12px;
}

.FormPreview .QuestionViewer:not(:last-child) {
	margin-bottom: 1rem;
}

.FormPreview .QuestionViewer .question-title {
	font-size: 1.25rem;
	line-height: 1.5rem;
}

.FormPreview .QuestionViewer .question-text {
	font-size: 0.875rem;
}

.FormPreview .QuestionViewer .question-text a {
	color: #0d6efd;
}

.FormPreview .AnswersList {
	max-height: 300px;
	overflow-x: hidden;
	overflow-y: auto;
}

.FormPreview .text-answer {
	background-color: var(--background-color);
	padding: 6px;
	border-radius: 6px;
}

.FormPreview .text-answer:not(:last-child) {
	margin-bottom: 0.5rem;
}

.FormPreview .range-bloc {
	font-size: 0.875rem;
	line-height: 1rem;
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 10px;
	width: 100%;
	max-width: 300px;
}

.FormPreview .range-chart {
	font-size: 1.25rem;
	letter-spacing: 0px;
	line-height: 1.375rem;
	overflow: hidden;
	cursor: pointer;
}

.AnswersTable {
	width: auto;
}

.AnswersTable.table-cont table {
	width: auto;
	min-width: 300px;
}

.AnswersTable th,
.AnswersTable td {
	min-width: 150px;
}

.AnswersTable td.answers-col {
	font-family: "Poppins-Bold";
}

@media screen and (max-width: 600px) {
	.AnswersTable.table-cont table {
		width: 100%;
	}

	.AnswersTable th,
	.AnswersTable td {
		min-width: auto;
	}
}
