.PageButton {
	font-size: 0.625rem;
	background-color: #fff;
	background-color: var(--main-menu-nav-links-btn-bg);
	border: 2px solid var(--main-menu-nav-links-btn-bg);
	overflow: hidden;
	color: white;
	color: var(--font-color);
	position: relative;
	z-index: 2;
	display: flex;
	transition-duration: 0.5s;
}


.PageButton.active {
	border: 2px solid var(--color-primary);
	background-color: var(--color-primary);
	color: white;
	color: var(--color-primary-font-color);
}

.PageButton.outlined,
.PageButton:not(.active):hover	
{
	border: 2px solid var(--color-primary);
}

.PageButton:not(.active).nav-link:focus {
	border: 6px solid var(--color-primary);
}

.PageButton:not(.active) .nav-link:focus-visible {
    border: 2px solid var(--color-primary);
    outline: 2px solid var(--color-primary);
    color: var(--font-color);
}


.PageButton .nav-link {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	text-decoration: none;
	padding: 0.5em;
	color: white;
	color: var(--font-color);
}

.PageButton a.nav-link,
.PageButton a.nav-link:hover
{
	color: white;
	color: var(--font-color);
}

.theme-dark .PageButton:not(.active) .nav-link-page-icon {
	filter: invert(1);
}

.PageButton .nav-link-page-icon {
	height: 50%;
	width: auto;
	aspect-ratio: 1;
}

.PageButton .nav-link .nav-link-page-title
{
	font-size: 0.625rem;
	line-height: 0.6875rem;
}

.Menu:not(.reduced-menu) .page-button-tooltip{
	display: none;
}

.PageButton .page-button-tooltip
{
	background-color: var(--background-color-secondary);
	font-size: 0.75rem;
	box-shadow: 0px 0px 4px 0px var(--shadow);
	padding: 5px 10px;
	border-radius: 6px;
	font-family: "Poppins-Regular";
}
