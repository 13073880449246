.calendar {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	--fc-highlight-color: rgba(var(--color-primary-rgb), 0.3);
}

.calendar > * {
	width: 100%;
	background-color: #f9f9f9;
	background-color: var(--background-color-secondary);
	padding: 15px;
	border-radius: 14px;
	margin-bottom: 20px;
}

.calendar .fc-toolbar-chunk {
	padding-right: 10px;
	overflow: hidden;
	display: flex;
}

.calendar .fc-toolbar-title {
	font-weight: 100;
	display: inline-block;
	vertical-align: middle;
	margin-left: 10px !important;
	text-transform: capitalize;
}

.calendar .fc-direction-ltr .fc-list-day-text,
.calendar .fc-direction-rtl .fc-list-day-side-text {
	text-transform: capitalize;
}

.calendar .fc-button:first-child {
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.calendar .fc-button:last-child:last-of-type {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.calendar .fc .fc-button-primary,
.calendar .fc .fc-button-primary:focus {
	color: black;
	color: var(--font-color);
	background-color: var(--background-color);
	border: none !important;
	box-shadow: none !important;
	font-weight: 100;
	text-transform: capitalize;
}

.calendar .fc .fc-button-primary:not(.fc-button-active, .active):hover,
.calendar .fc .fc-button-primary:active {
	color: var(--color-primary-font-color);
	background-color: rgba(var(--color-primary-rgb), 0.5) !important;
	border: none !important;
	box-shadow: none !important;
}

.calendar .fc-button-active {
	background-color: var(--color-primary) !important;
	color: var(--color-primary-font-color) !important;
}

.calendar .fc-theme-standard .fc-scrollgrid,
.calendar .fc-theme-standard .fc-list {
	border: none;
}

.calendar .fc-theme-standard td,
.calendar .fc-theme-standard th {
	border: none;
}

.calendar .fc .fc-col-header-cell-cushion {
	color: #bfbfbf;
	font-size: 1rem;
	font-weight: 100;
}

.calendar .fc .fc-scroller-harness {
	padding-bottom: 20px;
}

.calendar .fc-daygrid-day-frame {
	box-shadow: inset 0px 0px 0px 0px #00000000;
	border-radius: 6px;
	cursor: pointer;
}

.calendar .fc-daygrid-day-number,
.calendar .fc-daygrid-day-number:hover {
	color: grey;
}

.calendar .fc-daygrid-day.active {
	background-color: #ededed;
	background-color: rgba(var(--color-primary-rgb), 0.5) !important;
	border-radius: 6px;
}

.calendar .fc-daygrid-day.active .fc-daygrid-day-number {
	position: relative;
	z-index: 4;
	padding: 4px;
	background-color: #0f8dbd;
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
	border-radius: 0px 5px 0px 5px;
}

.calendar .fc-daygrid-day-frame.active,
.calendar .fc-daygrid-day-frame:hover {
	box-shadow: inset 0px 1px 12px -7px #00000073;
	transition-duration: 0.05s;
	background-color: #fafafa;
	background-color: var(--background-color-secondary);
}

.calendar .fc-daygrid-day.active .fc-daygrid-day-frame:hover {
	background-color: transparent;
	box-shadow: inset 0px 1px 12px -7px #00000073;
	transition-duration: 0.05s;
}

.calendar .fc .fc-cell-shaded,
.calendar .fc .fc-day-disabled {
	background-color: #f9f9f9;
	background-color: var(--background-color-secondary);
}

.calendar .fc-day-disabled > *:hover {
	background-color: #f9f9f9;
	background-color: var(--background-color-secondary);
	box-shadow: none;
	border-radius: 6px;
}

.calendar .fc .fc-daygrid-day.fc-day-today {
	background-color: #00adff36;
	background-color: rgba(var(--color-primary-rgb), 0.15);
	border-radius: 6px;
}

.calendar .new-event .fc-event-title:before {
	content: "";
	background-color: #e91e1e;
	padding: 3px;
	border-radius: 100px;
	overflow: hidden;
	display: inline-block;
	line-height: 0;
	margin-right: 3px;
}

/*
** -- List Display
*/

.calendar tr.fc-list-event td {
	cursor: pointer;
}

.calendar .fc-list-event-title {
	color: var(--font-color);
}

.calendar tr.fc-list-event td:first-child {
	border-top-left-radius: 6px;
	border-bottom-left-radius: 6px;
	color: grey;
}

.calendar tr.fc-list-event td:last-child {
	border-top-right-radius: 6px;
	border-bottom-right-radius: 6px;
}
.calendar .fc .fc-list-event:hover td {
	background-color: #f5f5f5;
	background-color: var(--fc-list-event-hover-bg-color, #f5f5f5);
	background-color: var(--background-color-darken);
}

/* Events */
.calendar .fc-h-event:not(.fc-event-selected) .fc-event-resizer {
	width: 15px !important;
	cursor: ew-resize !important;
}

.calendar .fc-daygrid-block-event .fc-event-time,
.calendar .fc-daygrid-block-event .fc-event-title {
	padding: 0px;
	margin-right: 5px;
	font-size: 0.625rem;
	word-break: break-word;
	text-align: right;
}

.calendar .fc-daygrid-dot-event {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	padding: 4px 0px;
	margin: 0px;
}

.calendar .fc .fc-daygrid-body-natural .fc-daygrid-day-events {
	text-align: right;
	line-height: 1rem;
	font-size: 1rem;
	margin-right: 3px;
}

.calendar .fc-daygrid-event-harness {
	/*width: 15px;*/
	display: inline-block;
	padding: 0;
}

.calendar .fc-daygrid-event-dot {
	margin: 0 0px;
}

.calendar .fc-daygrid-event {
	white-space: initial;
}

/* .fc-event-title, */
.calendar .fc-event-time {
	display: none;
}

.calendar .fc-timeGridWeek-view .fc-event-title,
.calendar .fc-timeGridDay-view .fc-event-title {
	display: initial;
}

.calendar .fc-event-title-container {
	text-align: left;
	padding-left: 5px;
}

.calendar .fc-toolbar .fc-button.active {
	background-color: #2e8aba;
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

@media screen and (max-width: 700px) {
	.calendar > *,
	.calendar .fc-col-header-cell-cushion {
		font-size: 0.75rem;
	}
}

@media screen and (max-width: 1280px) {
	.calendar .fc-daygrid-event-dot {
		border-width: 3px;
	}
}

@media (max-width: 850px) {
	.calendar .fc .fc-toolbar.fc-header-toolbar {
		display: flex;
		flex-direction: column;
	}

	.calendar .fc-header-toolbar .fc-toolbar-chunk {
		display: flex;
		width: 100%;
		margin-bottom: 4px;
		justify-content: space-between;
	}
}
