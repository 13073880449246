.font-thin
{
	font-family:'Poppins-Thin';
}

.font-ulight
{
	font-family:'Poppins-ULight';
}

.font-light
{
	font-family:'Poppins-Light';
}

.font-regular
{
	font-family:'Poppins-Regular';
}

.font-semibold
{
	font-family:'Poppins-SemiBold';
}

.font-bold
{
	font-family:'Poppins-Bold';
}

.font-heavy
{
	font-family:'Poppins-Heavy';
}

.font-italic
{
	font-family:'Poppins-Italic';
}

/*
** -- SF-Text
*/

@font-face {
	font-family:'Poppins-Thin';
	src:url(assets/fonts/Poppins/Poppins-Thin.ttf);
}

@font-face {
	font-family:'Poppins-ULight';
	src:url(assets/fonts/Poppins/Poppins-ExtraLight.ttf);
}

@font-face {
	font-family:'Poppins-Light';
	src:url(assets/fonts/Poppins/Poppins-Light.ttf);
}

@font-face {
	font-family:'Poppins-Regular';
	src:url(assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
	font-family:'Poppins-SemiBold';
	src:url(assets/fonts/Poppins/Poppins-SemiBold.ttf);
}

@font-face {
	font-family:'Poppins-Bold';
	src:url(assets/fonts/Poppins/Poppins-Bold.ttf);
}

@font-face {
	font-family:'Poppins-Heavy';
	src:url(assets/fonts/Poppins/Poppins-ExtraBold.ttf);
}

@font-face {
	font-family:'Poppins-Italic';
	src:url(assets/fonts/Poppins/Poppins-Italic.ttf);
}

