
.post-images-cont img
{
	width: 100%;
	z-index: 1;
}

.post-image img {
	pointer-events: none;
	background-color: var(--background-color-secondary);
}

.post-images-cont.single-image img {
	border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.post-images-slider {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
	position: relative;
}

.extend-image-btn {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background-color: var(--background-color);
    opacity: 0.8;
    border-radius: 86px;
    width: 25px;
    height: 25px;
    font-size: 0.9375rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-slide > div {
	display: flex;
    align-items: unset;
    justify-content: center;
    height: 100%;
}

.post-image {
	position: relative;
	align-items: center;
	outline: none;
}

.image-blur {
	background-position: center;
	background-size: cover;
	filter: blur(40px);
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 0;
}

.post-images-cont.extended .image-blur {
	display: none;
}

.post-images-cont.extended .post-image img {
	background-color: transparent;
}

/*
** -- Extended
*/

.post-images-cont.extended {
	position: absolute;
    top: 0;
    left: 0;
	padding: 20px;
    backdrop-filter: blur(11px);
    display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% + 2px);
    width: 100%;
	z-index: 4;
	margin-bottom: 0px!important;
    border-radius: 20px;
    overflow: hidden;
}

.post-images-cont.extended .extend-image-btn {
	position: absolute;
    top: 17px;
    left: 17px;
    font-size: 1.875rem;
    line-height:  1.875rem;
    padding: 5px;
    background-color: var(--background-color);
    border-radius: 60px;
    z-index: 2;
}

.post-images-cont.extended .post-images-slider {
	max-width: 100%;
	max-height: 80vh;
	position: unset;
}

.post-images-cont.extended .slick-active .post-image {
    z-index: 1;
}

.post-images-cont.extended .post-image
{
	position: unset;
}

.post-images-cont.extended .post-image img
{
	max-height: 80vh;
	width: auto;
	max-width: 100%;
	min-width: 200px;
	min-height: 200px;
	border-radius: 20px;
}

.slick-next:focus, .slick-prev:focus,
.slick-next:hover, .slick-prev:hover,
.slick-next, .slick-prev {
	background-color: #000;
    border-radius: 41px;
    padding-top: 1px;
}

.slick-prev {
    left: 5px;
}

.slick-next {
	right: 5px;
}

.slick-next, .slick-prev
{
	filter: invert(1);
	z-index: 2;
}

.post-images-preview
{
	display: flex;
    overflow: auto;
    margin-left: auto;
    max-width: 100%;
}

.post-images-preview img {
    width: 70px;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    border: 1px solid var(--background-color);
	cursor: pointer;
}

.post-images-preview img:last-child {
    margin-right: 0;
}

@media screen and (max-width:700px) {
	.post-images-preview {
		padding: 0px 0.5rem;
	}
}
