.MobileNavBar {
	position: relative;
	padding: 5px 10px;
    box-shadow: 0px 5px 10px 5px var(--shadow);
    z-index: 10;
	background-color: var(--background-color-secondary);
	display: none;
	justify-content: space-between;
	width: 100%;
	border-top: 1px solid var(--background-color);
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
}

.MobileNavBar .dropdown__menu-portal {
	position: absolute;
	z-index: 15;
	top: 0px;
	left: 0px;
	height: 100vh;
	width: 100%;
}

.NavButton {
	transition-duration: 0.3s;
	border-bottom: 2px solid transparent;
	max-width: calc(100% / 5);
	overflow: hidden;
}

.NavButton.active {
	border-bottom: 2px solid var(--color-primary);
}

.NavButton > .nav-link {
	font-size: 0.75rem;
	padding: 0px 5px;
	color: var(--font-color);
}

.NavButton > .nav-link > * {
	width: 100%;
}

.MobileNavBar .nav-button-page-icon {
	width: 20px;
	height: 20px;
}

.theme-dark .MobileNavBar .nav-button-page-icon {
	filter: invert(1);
}

.MobileNavBar .nav-button-page-title {
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: keep-all;
	white-space: nowrap;
}

.MobileNavBar .slick-slider {
	width: 100%;
}

.MobileNavBar .slick-list {
	width: 100%;
	z-index: 2;
	overflow: unset;
	width: calc(100% + 10px);
	margin-left: -5px;
	background-color: var(--background-color-secondary);
}

.MobileNavBar .slick-track {
	margin-bottom: 3px;
}

.MobileNavBar .slick-dots {
	top: -16px;
	bottom: unset;
	height: auto;
	display: flex!important;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.MobileNavBar .slick-dots .DotsNav {
	background-color: var(--background-color-secondary);
	margin: 0;
	box-shadow: 0px -4px 10px -2px var(--shadow);
	position: relative;
}

.MobileNavBar .slick-dots .DotsNav ul {
	display: flex;
	height: 20px;
	top: -2px;
	position: relative;
}

.MobileNavBar:not(.sharp-dots) .slick-dots .DotsNav:before {
	content: "";
	position: absolute;
	left: -5px;
	bottom: 0px;
	background-color: var(--background-color-secondary);
	width: 5px;
	height: 20px;
	border-top-left-radius: 10px;
}

.MobileNavBar:not(.sharp-dots) .slick-dots .DotsNav:after {
	content: "";
	position: absolute;
	right: -5px;
	bottom: 0px;
	background-color: var(--background-color-secondary);
	width: 5px;
	height: 20px;
	border-top-right-radius: 10px;
}

.MobileNavBar.sharp-dots .slick-dots .DotsNav:before {
	content: "";
	position: absolute;
	left: -10px;
	bottom: 0px;
	border-left: 10px solid transparent;
	border-bottom: 20px solid var(--background-color-secondary);
}

.MobileNavBar.sharp-dots .slick-dots .DotsNav:after {
	content: "";
	position: absolute;
	right: -10px;
	bottom: 0px;
	border-right: 10px solid transparent;
	border-bottom: 20px solid var(--background-color-secondary);
	/* z-index: 1; */
}

.MobileNavBar .slick-dots li {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	margin: 0px;
	padding: 0;
	cursor: pointer;
}

.MobileNavBar .slick-dots li button:before {
	color: var(--color-primary);
}

/* .slick-dots li.slick-active button:before {
	color: var(--color-primary);
} */

.MobileNavBar .NavBarPage {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.MobileNavBar .MenuToggler {
	color: white;
	cursor: pointer;
	display: none;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
}

.MobileNavBar .MenuToggler hr {
	margin: 4px 0px;
}

/*
** -- Page 2
*/

.MobileNavBar .AdminMenuHead {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

.theme-dark .MobileNavBar .PlateformChanger {
	background-color: var(--background-color);
}

@media screen and (max-width: 700px) {
	.MobileNavBar {
		display: flex;
		justify-content: space-around;
		width: 100%;
	}
}
