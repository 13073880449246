.page-cont.contacts-page-cont {
	padding-bottom: 0px;
}

.ContactsPage {
	padding-bottom: 0px;
}

.users-cont {
	height: 100%;
    width: auto;
    box-sizing: border-box;
	display: flex;
	flex-direction: column;
}


.users-cont .infinite-scroll-component__outerdiv {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.users-cont .users-list.infinite-scroll-component {
	margin-left: -12.5px;
	padding: 0;
	padding-bottom: 2rem;
}
