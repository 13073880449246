/*
** -- Tile
*/
.SiteTile.tile {
	width: calc(100% - 25px);
	flex-direction: row;
	border-radius: 10px;
}

.SiteTile .site-logo {
	align-self: center;
}

.SiteTile .title {
	font-size: 1.25rem;
	line-height: 1.25rem;
}

.SiteTile:not(.active):hover {
	transform: scale(0.97) !important;
}

.SiteTile .location {
	font-size: 0.9375rem;
	line-height: 0.9375rem;
}

.SiteTile .nbr-member-txt {
	font-size: 0.75rem;
	color: grey;
}

.SiteTile {
	position: relative;
}

.SiteTile .SaveUserBtn {
	opacity: 0;
	transition-duration: 0.3s;
	position: absolute;
	top: 15px;
	right: 15px;
}

.SaveUserBtn:focus,
.SiteTile:hover .SaveUserBtn:not(.saved),
.SaveUserBtn.saved {
	opacity: 1;
}

/*
** -- responsive
*/

.SitesList .SiteTile.tile {
	width: calc(100% - 25px);
}

@media (min-width: 700px) {
	.SitesList .SiteTile.tile {
		width: calc(50% - 25px);
	}
}

@media (min-width: 1250px) {
	.SitesList .SiteTile.tile {
		width: calc(33% - 25px);
	}
}

@media (min-width: 2500px) {
	.SitesList .SiteTile.tile {
		width: calc(25% - 25px);
	}
}
