
.steps-nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1rem;
	padding: 0.625rem 1.375rem;
	/* background-color: var(--background-color-secondary); */
	border-radius: 10px;
}

.steps-nav > * {
	white-space: nowrap;
	width: 100%;
}

.steps-nav .btn {
	height: 100%;
}


@media screen and (max-width: 700px) {

	.steps-nav {
		flex-wrap: wrap;
	}

	.steps-nav > * {
		flex: 1 1 49%;
		width: auto;
	}
	
}
