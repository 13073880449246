.FormQuestion {
	min-height: 50px;
}

.FormQuestion .question-drag-ph {
	background-color: rgb(127, 127, 127);
	background-color: var(--color-primary);
	opacity: 0.1;
	height: 100px;
	border-radius: 6px;
	margin-bottom: 0.5rem;
}

.FormQuestion {
	border-radius: 6px;
	padding: 1em;
	background-color: white;
	background-color: var(--background-color);
	border-top: 0;
	border-bottom: 0;
	border-right: 0;
	border-left: 6px solid white;
	border-left: 6px solid var(--background-color);
	transition-duration: 0.1s;
}

.FormQuestion.active {
	border-left: 6px solid #00f;
	border-left: 6px solid var(--color-primary);
	transition-duration: 0.2s;
}

.FormQuestion .question-handle {
	height: 10px;
	position: relative;
	top: -10px;
	width: 100%;
	cursor: ns-resize;
	background-image: url(../../assets/h-handle.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 60%;
}

.FormQuestion .question-image {
	position: relative;
	background-color: var(--background-color-secondary);
	border-radius: 6px;
}

.FormQuestion .rm-question-image-btn {
	position: absolute;
	top: 5px;
	left: 5px;
}

.FormQuestion .question-image img {
	border-radius: 6px;
}

.FormQuestion .question-title-cont {
	position: relative;
}

.FormQuestion.requiered:not(.active) .question-title-cont:before {
	content: "*";
	display: block;
	color: red;
	position: absolute;
	top: 6px;
	left: -12px;
	font-size: 1.25rem;
	line-height: 1.5625rem;
}

.FormQuestion .question-title {
	font-family: "Poppins-Bold";
	font-size: 1.25rem;
}

.FormQuestion .question-text {
	font-weight: 300;
}

.FormQuestion .question-text a {
	color: #0d6efd;
}

.FormQuestion .dropdown-select-type,
.FormQuestion .requiered-switch-cont {
	display: none;
}

.FormQuestion .dropdown-select-type,
.FormQuestion .requiered-switch-cont {
	display: flex;
}

.FormQuestion .question-answers {
	font-weight: 100;
}

.FormQuestion .question-toolbar {
	display: none;
	border-top: 1px solid #cecece;
}

.FormQuestion .section-toolbar-btn,
.FormQuestion .question-toolbar-btn {
	font-size: 1.25rem;
	cursor: pointer;
}

.FormQuestion.active .question-toolbar {
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.FormQuestion.active .option-handle {
	width: 10px;
	height: 30px;
}

.FormQuestion .option-handle {
	cursor: ns-resize;
	background-image: url(../../assets/v-handle.png);
	background-repeat: no-repeat;
	background-position: center;
	background-size: auto 60%;
}

.FormQuestion .option-toolbar-btn {
	font-size: 0.875rem;
	cursor: pointer;
}

.FormQuestion .option-cont .option-toolbar-btn {
	display: none;
}
.FormQuestion .option-cont:hover .option-toolbar-btn {
	display: block;
}
