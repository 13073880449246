.MuiPaper-root.Table {
	box-shadow: none;
	background-color: transparent;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	font-weight: 300;
	font-size: 0.75rem;
	line-height: 0.75rem;
	color: var(--font-color);
}

.Table .MuiTable-root {
	border-spacing: 7px;
	border-collapse: unset;
	color: var(--font-color);
}

.Table .MuiToolbar-root {
	color: var(--font-color);
}

.MuiTableHead-root .MuiCheckbox-root .MuiSvgIcon-root {
	color: var(--color-primary);
}

.MuiTableHead-root .MuiCheckbox-root input[type="checkbox"]:before {
	border: 2px solid var(--color-primary) !important;
}

.MuiTableBody-root .MuiCheckbox-root .MuiSvgIcon-root {
	display: none;
}

[class*="MTableToolbar-searchField"] {
	margin-left: 16px;
	padding-left: 6px !important;
	background-color: var(--background-color-secondary);
	border-radius: 6px;
	padding: 3px;
}

.MuiInput-underline:before {
	border-bottom: none !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid var(--color-primary) !important;
}

.MuiTablePagination-toolbar {
	background-color: var(--background-color-secondary);
	border-radius: 6px;
}

[class*="MTablePaginationInner"],
.MuiSvgIcon-root {
	color: var(--font-color) !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
	color: var(--font-color) !important;
}

.MuiTableSortLabel-icon {
	color: var(--color-primary) !important;
}

/* CHECKBOX */

.Table .MuiCheckbox-root {
	padding: 0;
	width: 18px;
	height: 18px;
	border-radius: 4px;
	overflow: hidden;
}

.Table .MuiCheckbox-root input[type="checkbox"]:checked {
	opacity: 1;
}

.Table .MuiCheckbox-root input[type="checkbox"]:not(:checked),
.Table .MuiCheckbox-root input[type="checkbox"]:checked {
	position: absolute;
	left: 0;
}

.Table.selectable tr:not(.MuiTableRow-footer) th:first-child,
.Table.selectable tr:not(.MuiTableRow-footer) td:first-child {
	width: 30px !important;
}

/* on prépare le label */
.Table.selectable input[type="checkbox"]:not(:checked),
.Table.selectable input[type="checkbox"]:checked {
	cursor: pointer;
	width: 18px !important;
	height: 18px;
	border: none;
}

/* Aspect de la case */
.Table.selectable
	input[type="checkbox"]:not(.checkboxTable):not(:checked)::before,
.Table.selectable input[type="checkbox"]:not(.checkboxTable):checked::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background: var(--background-color-secondary);
	transition: all 0.275s;
	border: none;
	border-radius: 4px;
	box-sizing: border-box;
}

/* Aspect de la coche */
.Table.selectable
	input[type="checkbox"]:not(.checkboxTable):not(:checked)::after,
.Table.selectable input[type="checkbox"]:not(.checkboxTable):checked::after {
	content: "";
	position: absolute;
	top: 0px;
	left: 0px;
	transition: all 0.2s;
	background-color: #0b608d;
	background-color: var(--color-primary);
	border-radius: 4px;
	width: 18px;
	height: 18px;
	opacity: 1;
}

/* Aspect non cochée */
.Table.selectable input[type="checkbox"]:not(:checked) {
}

.Table.selectable input[type="checkbox"]:not(:checked)::after {
	opacity: 0;
	transform: scale(0);
}

/* Aspect cochée */
.Table.selectable input[type="checkbox"]:checked::after {
	opacity: 1;
	transform: scale(1);
}

/* Disabled checkbox */
.Table.selectable input[type="checkbox"]:disabled:not(:checked)::before,
.Table.selectable input[type="checkbox"]:disabled:checked::before {
	box-shadow: none;
	border-color: #bbb;
	background-color: #e9e9e9;
}

/* Disabled checked */
.Table.selectable input[type="checkbox"]:disabled:checked::after {
	color: #777;
}

.Table.selectable input[type="checkbox"]:disabled {
	color: #aaa;
	cursor: not-allowed;
}

/* Accessibility focus */
.Table.selectable input[type="checkbox"]:checked:focus::before,
.Table.selectable input[type="checkbox"]:not(:checked):focus::before {
}

.Table ::-webkit-scrollbar {
	width: 4px;
	height: 6px;
}
