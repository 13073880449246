.PagesGroupPage {
	background-color: var(--background-color);
	display: flex;
	flex-direction: column;
}

.PagesGroupPage .MenuToggler {
	display: block;
}

.pages-group-title {
	font-size: 3rem;
	color: var(--color-primary)
}

.PagesGroupTiles {
	display: flex;
	flex-wrap: wrap;
	height: 100%;
    overflow: auto;
}

.PagesGroupTile {
	background-color: var(--background-color-secondary);
	border-radius: 24px;
	padding: 10px;
	margin: 0.75rem;
	min-width: 100px;
	min-height: 100px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition-duration: 0.3s;
}

.PagesGroupTile:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.PagesGroupTile .nav-link {
	color: var(--font-color);
	height: 100%;
	width: 100%;
}

.PagesGroupPage .nav-link-page-icon {
	width: 50px;
	height: 50px;
}

.theme-dark .PagesGroupPage .nav-link-page-icon{
	filter: invert(1);
}
