.LoginForm .login-input * {
	--input-color: transparent;
}

.LoginForm .inputs-wrapper label {
	border-bottom: 1px solid #fff;
}

.LoginForm input {
	text-align: center;
	color: white;
}

.LoginForm input::placeholder {
	color: white;
	text-align: left;
}

.LoginForm button.back-btn {
	border-radius: 50px;
	border: 2px solid white;
	padding: 5px;
	/* line-height: 0; */
	width: 30px;
	height: 30px;
}

.LoginForm .arrow-icon {
	transform: translate(0px, 0px);
	transition-duration: 0.4s;
}

.LoginForm .back-btn:hover .arrow-icon {
	transform: translate(-5px, 0px);
}
