.UsersList {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: space-between;
}

.UsersList .list {
	display: flex;
	align-items: center;
	overflow-x: auto;
	overflow-y: hidden;
	padding: 5px 0px;
}

.UsersList .more-users {
	min-width: fit-content;
	margin-left: 5px;
}

.UsersList .more-users:empty {
	display: none;
}

.UsersList .more {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
	border-radius: 3px;
	padding: 2px 3px;
	font-size: 0.75rem;
}

.UsersList ::-webkit-scrollbar {
	width: 4px;
	height: 2px;
}

.userlist-placeholder
{
	color: grey;
	font-size: 0.75rem;
	line-height:  0.875rem;
}

.UsersList.dark-bg .user-image {
	background-color: var(--background-color-secondary);
}
