
.ExistingsDocs .DocsList {
    height: 40vh;
}

.ExistingsDocs .SearchBar {
	background-color: var(--background-color);
}


.UploadDoc .upload-btn {
	line-height: 0rem;
	padding: 10px;
	font-size: 1.25rem;
	border-radius: 100px;
}

.DocsList .del-btn {
	font-size: 1rem;
	margin-left: 2px;
}
