
.image-editor-cont
{
	position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
	z-index: 3;
	background-color: var(--background-color);
}

.tui-image-editor-container
{
	background-color: var(--background-color);
	color: white;
}

.tui-image-editor-container .tui-image-editor-main-container {
	background-color: var(--background-color-secondary)!important;
	color: white;
}

.tui-image-editor-container .tui-image-editor-help-menu,
.tui-image-editor-container .tui-image-editor-controls {
	background-color: var(--background-color-darken);
}

.tui-image-editor-header-logo {
	display: none;
}

.tui-image-editor-container .tui-image-editor-help-menu {
	display: flex;
    justify-content: center;
    overflow: visible;
}

.tui-image-editor-container .tui-image-editor-header-buttons, .tui-image-editor-container .tui-image-editor-controls-buttons {
    float: left;
}

.tui-image-editor-item.image-editor-btn
{
	/* background-color: var(--color-primary); */
	border-radius: 30px!important;
	padding: 5px 10px;
}

.tui-image-editor-partition.tui-image-editor-newline {
	display: none!important;
}

.tui-image-editor-container .tui-image-editor-submenu {
    height: fit-content;
    padding-top: 10px;
}

.tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 0px;
}

.tui-image-editor-menu-crop .tie-crop-preset-button .preset  {
	display: none!important;
}

.tie-btn-text.tui-image-editor-item.btn-save-image
{
	background-color: var(--color-primary);
}

.tie-btn-text.tui-image-editor-item.btn-cancel-image
{
	background-color: red;
}

.tui-image-editor-help-menu {
	position: relative;
	display: flex;
	overflow-x: auto;
	left: unset;
	transform: unset;
	-webkit-transform: unset;
}

/* Bouttons Appliquer/annuler le recadrage */
.tie-crop-button.action .tui-image-editor-button {
    display: inline-flex!important;
    align-items: center;
}

.tui-image-editor-submenu {
	background-color: rgba(var(--background-color-rgb), 0.9);
}

.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-button > label {
	color: var(--font-color)!important;
}

.tui-image-editor-container .tui-image-editor-menu > .tui-image-editor-item {
	padding: 5px;
}

.tui-image-editor-submenu-style,
.tui-image-editor-submenu > * {
	background-color: transparent!important;
}

/*
** -- color picker
*/

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-container ul
{
	display: flex;
	flex-wrap: wrap;
}

.tui-image-editor-container .color-picker-control .tui-colorpicker-palette-button {
	border: 0;
	border-radius: 100%;
	margin: 2px;
	background-size: cover;
	font-size: 0.0625rem;
	width: 15px;
	height: 15px;
}

.tui-image-editor-container .color-picker-control {
	border: none;
	background-color: var(--background-color-darken);
	border-radius: 6px;
}

.tui-image-editor-container .color-picker-control .triangle {
	border-top: 8px solid var(--background-color-darken);
}

.tui-image-editor-container div.tui-colorpicker-clearfix {
	background-color: var(--background-color);
	border: none;
	border-radius: 6px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
}

.tui-colorpicker-palette-preview {
	overflow: hidden;
}

.tui-image-editor-container .tui-colorpicker-palette-hex {
	color: var(--font-color);
	background-color: var(--background-color-darken);
	padding-left: 10px;
	font-size: 0.8125rem;
	font-family: 'Poppins-Regular';
}

/*
** -- History
*/
.tui-image-editor-container .tui-image-editor-help-menu .tie-panel-history {
	background-color: var(--background-color);
	color: var(--font-color);
}

.tui-image-editor-container .tui-image-editor-help-menu .opened .tie-panel-history:before {
	border-bottom-color: var(--background-color)!important;
}

.theme-dark .tui-image-editor-container .tui-image-editor-help-menu .tie-panel-history .history-list .history-item .tui-image-editor-history-item .history-item-icon {
	filter: invert(1);
}

.tui-image-editor-help-menu use.hover.use-default,
.tui-image-editor-menu use.normal.use-default,
/* .tui-image-editor-help-menu use.normal.use-default, */
.tui-image-editor-submenu use.active.use-default,
.tui-image-editor-menu use.active.use-default, .tui-image-editor-help-menu use.active.use-default {
	fill-rule: evenodd;
	fill: var(--color-primary)!important;
	stroke: var(--color-primary)!important;
}

/*
** -- slider
*/

.tui-image-editor-container .tui-image-editor-virtual-range-bar {
	color: #666;
	background-color: #666;
	background-color: var(--background-color-darken)!important;
}

.tui-image-editor-container .tui-image-editor-virtual-range-subbar {
	color: #d1d1d1;
	background-color: #d1d1d1;
	background-color: var(--font-color)!important;
}

.tui-image-editor-container .tui-image-editor-virtual-range-pointer {
	color: var(--font-color)!important;
	background-color: var(--font-color)!important;
}

/*
** -- Help button
*/

@media screen and (max-width: 700px) {
	.image-editor-cont {
		position: fixed;
		z-index: 10;
		padding-bottom: 60px;
	}	
}
