.step-cont.images-step,
.step-cont.preview-step
{
	height: 100%;
}

.edit-drawer .step-cont.images-step .image-editor-cont {
	max-height: unset;
	min-height: unset;
	height: 100%;
	border-radius: 10px;
	overflow: hidden;
}

.edit-drawer .post-preview-cont {
	max-width: 500px;
	width: auto;
	height: 100%;
}

.edit-drawer .post-linked-docs-cont {
	background-color: rgba(var(--background-color-rgb), 0.97);
}

.edit-drawer .post-preview .document-tile {
	background-color: var(--background-color-secondary);
}

.edit-drawer .post-preview-cont .pdf-viewer-cont {
	background-color: var(--background-color);
}
