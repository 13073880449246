.PostsPage {
	overflow: visible;
	height: calc(100% + 1.5rem)!important;
}

.post-page-cont {
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
}

.post-page {
	overflow: hidden;
	width: 100%;
}

.PostPageBody {
	gap: 1.5rem;
	justify-content: space-between;
}

.posts-cont
{
	display: flex;
	height: 100%;
	overflow: hidden;
    box-sizing: border-box;
}

.PostsTable {
	margin-left: -0.5rem;
	width: calc(100% + 0.5rem)!important;
}

.infinite-scroll-component__outerdiv
{
	height: 100%;
	width: 100%;
}

.infinite-scroll-component
{
    overflow-y: auto;
	overflow-x: hidden;
	align-content: flex-start;
}

/*
** -- previex
*/
.PostPageBody .post-preview-cont:not(.extended) {
    padding-bottom: 1.5rem;
}

/*
** -- Fill form
*/

.post-page .FormFiller {
	height: 100%;
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    min-width: 0;
}

.post-page .FormFiller .edit-drawer {
	height: 100%;
	padding-bottom: 50vh;
}

@media screen and (max-width: 1200px) {
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) {
		position: absolute;
		width: 100%;
		min-width: auto;
		height: 100%;
		backdrop-filter: blur(5px);
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0px;
		left: 0%;
		top: 0px;
		z-index: 3;
		border-radius: 0px;
		overflow: hidden;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-preview {
		height: 100%;
		max-height: unset;
		max-width: 100%;
		overflow-y: auto;
		margin-right: 0;
		border-radius: 0;
		position: relative;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-content {
		overflow: visible;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-extend-btn
	{
		/* position: fixed;
		top: 10px;
		left: 0px;
		font-size: 1.875rem;
		line-height:  1.875rem;
		padding: 5px;
		background-color: var(--background-color);
		border-radius: 60px;
		z-index: 2; */
		display: none;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .extend-image-btn
	{
		display: none;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-images-cont:not(.extended) .post-images-slider img {
		max-width: 100%;
		max-height: 80vh;
		width: auto;
	}
	
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-comments-drawer,
	.PostsPage .PostPageBody .post-preview-cont:not(.no-responsive) .post-linked-docs-cont {
		position: fixed;
	}
}
