.DatePicker {
	width: 100%;
	font-family: "Poppins-SemiBold";
	font-size: 1.5rem;
}

.react-datepicker {
	font-family: "Poppins-Regular";
	background-color: var(--background-color);
	color: var(--font-color);
	border: 3px solid var(--background-color-secondary);
	box-shadow: 0 0 5px 1px #00000026;
}

.react-datepicker__time-container .react-datepicker__time {
	background: var(--background-color);
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color)!important;;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover
{
	background-color: rgba(var(--color-primary-rgb), 0.5);
	color: var(--color-primary-font-color)!important;;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color)!important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
	background-color: rgba(var(--color-primary-rgb), 0.3);
	color: var(--color-primary-font-color)!important;
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__month-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__quarter-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range),
.react-datepicker__month--selecting-range .react-datepicker__year-text--in-range:not(.react-datepicker__day--in-selecting-range,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__year-text--in-selecting-range) {
  color: #000!important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color)!important;;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
	background-color: rgba(var(--color-primary-rgb), 0.5);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color)!important;;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
	border: 1px solid var(--color-primary);
	border-radius: 5px;
}

.react-datepicker__header {
	background-color: #f0f0f0;
	background-color: var(--background-color);
	color: var(--font-color);
	border-bottom: 1px solid var(--background-color-secondary);
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
	color: var(--font-color);
	text-transform: capitalize;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
	color: var(--font-color);
}

.react-datepicker__day--disabled, .react-datepicker__month-text--disabled, .react-datepicker__quarter-text--disabled, .react-datepicker__year-text--disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.react-datepicker__time-container {
	border-left: 1px solid var(--background-color-secondary);
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
	border-bottom-color: var(--background-color);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
	border-bottom-color: var(--background-color);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
	border-top-color:  var(--background-color);
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
	border-top-color:  var(--background-color);
}
