.post-tile {
	position: relative;
	display: flex;
	border-radius: 25px;
	height: 200px;
	box-sizing: border-box;
	background-color: white;
	background-color: var(--tiles-bg-color);
	border: 1px solid var(--tiles-bg-color);
	cursor: pointer;
	color: var(--font-color);
	transition-duration: 0.3s;
	flex: 1 1 200px;
}

.post-tile.active {
    border: 3px solid var(--color-primary);
    pointer-events: none;
}

.post-tile.loading {
	min-height: 100px;
	color: transparent;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	padding: 20px !important;
}

.post-tile:not(.active):hover {
	transform: scale(0.97)!important;
}

.post-tile.no-image .post-content {
	width: 100%;
}

.post-tile .post-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
	display: flex;
	flex-direction: column;
}

.post-tile .author-popper {
	position: fixed!important;
	transform: none!important;
}

.post-tile .PostDates {
	display: flex;
	font-size: 0.625rem;
	margin-left: auto;
}

.differed-picto {
	color: rgb(0, 171, 171);
	margin-bottom: 4px;
}

.priority-picto {
	color: red;
}

.tag {
	width: fit-content;
	border-radius: 5px;
	padding: 0px 5px;
}

.post-tile .moderated-tag
{
	background-color: #cb151e;
	color: white;
	font-size: 0.75rem;
}

.post-tile .new-tag
{
	background-color: #cb151e;
	color: white;
}

.post-tile .post-sub-title {
    font-size: 0.75rem;
}

.post-tile .post-title {
	font-size: 1rem;
	line-height:  1.25rem;
	word-break: break-word;
}

.post-tile .post-text {
	color: grey;
	line-height:  0.9375rem;
	font-size: 0.75rem;
	position: relative;
	max-height: 120px;
}

.post-tile .post-text * * {
    font-size: 0.65rem;
    line-height: 0.82rem;
}

.post-text-hidder {
	background: linear-gradient(0deg, var(--tiles-bg-color) 20%, #0000);
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
	color: #808080;
	font-size: 0.75rem;
	max-height: 100px;
}

.post-ddr-picto
{
	background-image: url(./ddr.png);
    background-size: cover;
    padding: 6%;
    position: absolute;
    background-position-x: -2px;
    background-position-y: -2px;
    background-repeat: no-repeat;
    border-top-left-radius: 30px;
    left: 0;
    top: 0;
    z-index: 1;
    border-top: 8px solid var(--tiles-bg-color);
    border-left: 8px solid var(--tiles-bg-color);
    transition-duration: 0.3s;
}


/*
** -- Column disp
*/

.post-tile.column 
{
	flex-direction: column;
}

.post-tile.column .post-image,
.post-tile.column .post-content
{
	width: 100%;
}

.post-tile.column .post-image
{
	border-bottom-left-radius: 0;
	border-top-right-radius: 10px;
}

.PostTileHead,
.post-tile .mobile-post-infos,
.post-tile .TileImage .mobile-author-preview {
	display: none;
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (max-width: 700px) {
	.post-tile {
		width: calc(100% - 25px);
		min-width: auto;
		flex-direction: column;
    	height: auto;
	}

	.post-tile .TileImage {
		position: relative;
		width: 100%;
	}
	
	.post-tile .TileImage .post-image {
		border-radius: 0px;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
	}

	.post-tile .image-cont {
		position: relative;
	}

	.post-tile .post-content .posts-list-author-preview {
		display: none;
	}

	.PostTileHead {
		display: flex;
	}

	.post-tile:not(.tile-head-display) .PostTileHead {
		display: none;
	}

	/* same display as in Mobile APP disabled for now */
	.post-tile:not(.tile-head-display) .TileImage .mobile-author-preview {
		display: flex;
		position: absolute;
		bottom: -1px;
		left: -1px;
		background-color: var(--tiles-bg-color);
		padding: 0.25rem;
		border-top-right-radius: 6px;
	}

	.post-tile.post-tile.tile-head-display .PostInfos {
		display: none!important;
	}

	.post-tile.tile-head-display .mobile-post-infos .PostDates {
		display: none!important;
	}

	.PostInfos .PostDates {
		display: none!important;
	}

	.post-tile .mobile-post-infos {
		display: flex;
	}
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (max-width: 550px) {
	.post-tile {
		width: calc(100% - 25px);
		min-width: 100%;
	}
}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 700px) and (max-width: 1200px) {
	.post-tile {
		width: calc(50% - 25px);
		min-width: auto;
	}

	.post-tile .TileImage {
		max-width: 200px;
	}

}

/* XL - X-Large devices (large desktops, 1200px and up)*/
@media (min-width:1200px) and (max-width: 1400px) {
	.post-tile {
		width: calc(100% - 25px);
		min-width: calc(100% - 25px);
	}

	.posts-cont:not(.no-cur) .post-tile {
		margin-right: 0;
	}

	.no-cur .post-tile {
		width: calc(50% - 25px);
		min-width: calc(50% - 25px);
	}

	.post-tile .TileImage
	{
		max-width: 200px;
	}
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {
	.post-tile {
		width: calc(50% - 25px);
		min-width: calc(50% - 25px);
	}

	.no-cur .post-tile {
		width: calc((100% / 3) - 25px);
		min-width: calc((100% / 3) - 25px);
	}

	.post-tile.loading div {
		content: "";
		background-image: linear-gradient(90deg, var(--background-color) 0%, transparent 50%, var(--background-color));
		animation-duration: 5s;
	}
}

/* XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1700px) {
	.post-tile {
		width: calc((100% / 2) - 25px);
		min-width: calc((100% / 2) - 25px);
	}
	
	.no-cur .post-tile {
		width: calc(25% - 25px);
		min-width: calc(25% - 25px);
	}
}

@media (min-width: 2300px) {
	.post-tile {
		width: calc((100% / 3) - 25px);
		min-width: calc((100% / 3) - 25px);
	}
	
	.no-cur .post-tile {
		width: calc(25% - 25px);
		min-width: calc(25% - 25px);
	}
}

@media (min-width: 2900px) {
	.post-tile {
		width: calc(25% - 25px);
		min-width: calc(25% - 25px);
	}
}

