.Button.btn {
    display: flex;
    width: 100%;
	border: none;
    border-radius: 0.3125rem;
    background-color: white;
    background-color: var(--background-color-secondary);
	color: var(--font-color);
	font-family: 'Poppins-Regular';
	align-items: center;
	justify-content: center;
	padding: 0.5rem 0.625rem;
    line-height:  0.9375rem;
    font-size: 0.875rem;
    cursor: pointer;
    vertical-align: middle;
	transition-duration: 0.3s;
}

.Button.btn-transp {
	background-color: transparent;
}

.rounded-buttons .Button:not(.not-rounded) {
	border-radius: 1000px;
	padding: 5px 10px;
}

.Button.unstyled {
	padding: 0;
	margin: 0;
	background-color: transparent;
	border: none;
	color: inherit;
}

.Button.btn:hover {
	filter: brightness(0.90);
}

.Button.btn-red,
.Button.btn-h-red:hover {
	background-color: #FF0A18;
	color: white;
}

.theme-dark .Button.btn-red,
.theme-dark .Button.btn-h-red {
	background-color: #ff4650;
}

.Button.btn-blue,
.Button.btn-h-blue:hover {
	background-color: #0080B9;
	color: white;
}

.Button.btn-cyan,
.Button.btn-h-cyan:hover {
	background-color: #009FE3;
	color: white;
}

.Button.btn-grey,
.Button.btn-h-grey:hover {
	background-color: #C1BFC1;
	color: white;
}

.Button.btn-green,
.Button.btn-h-green:hover
{
	background-color: rgb(0, 185, 83);
	color: white;
}

.Button.btn-black,
.Button.btn-h-black:hover {
	background-color: rgb(25, 25, 25);
	color: white;
}

.Button.btn-yellow,
.Button.btn-h-yellow:hover {
	background-color: #FFBE00;
	color: white;
}

.Button.btn-primary,
.Button.btn-h-primary:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.Button.btn-bg,
.Button.btn-h-bg:hover {
	background-color: var(--background-color-darken);
	color: var(--font-color);
}

.Button.btn-bg-light,
.Button.btn-h-bg-light:hover {
	background-color: var(--background-color-lighten);
	color: var(--font-color);
}

.Button.btn-bg-dark,
.Button.btn-h-bg-dark:hover {
	background-color: var(--background-color-darken);
	color: var(--font-color);
}

.Button.btn-transparent,
.Button.btn-h-transparent:hover {
	background-color: transparent;
}

.Button.disabled,
.Button.disabled * {
	pointer-events: visible!important;
	cursor: not-allowed!important;
}

.Button.loading,
.Button.loading * {
	pointer-events: visible!important;
	cursor: wait!important;
}

.buttons-group {
	display: flex;
}

.buttons-group .Button:not(:last-child):not(:first-child) {
	border-radius: 0;
}

.buttons-group .Button:first-child:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 1px solid var(--background-color);
}

.buttons-group .Button:last-child:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

/* Icon */
.Button svg {
	margin-bottom: 1px;
}

/*
**
*/

.linked-buttons {
	display: flex;
	align-items: center;
	background-color: var(--background-color);
	border-radius: 6px;
	overflow: hidden;
}

.rounded-buttons .linked-buttons {
	border-radius: 1000px;
}

.linked-buttons > .Button {
	padding: 5px 10px;
	width: 100%;
}

.linked-buttons > .Button:not(:last-child)
{
	border-right: 1px solid var(--background-color-secondary);
}

.linked-buttons > .Button.active {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}
