.preview-drawer {
	display: flex;
	flex-direction: column;
	position: fixed;
	right: -100%;
	top: 0px;
	height: 100%;
	width: -moz-fit-content;
	width: fit-content;
	z-index: 3;
	/* transition-duration: 0.3s; */
	border-radius: 0!important;
	box-shadow: 0px 0px 8px 0px var(--main-menu-shadow);
	background-color: var(--background-color);
}

.preview-drawer > div:not(.close-preview-drawer-btn) {
	padding: 20px;
	padding-top: 60px;
}

.preview-drawer.full-screen.open {
	position: absolute;
	width: 100%;
	left: 0;
}

/* .preview-drawer.open {
	right: 0;
	transition-duration: 0.6s;
} */

.close-preview-drawer-btn {
	font-size: 1.875rem;
	line-height: 0;
	position: absolute;
	top: 10px;
	left: 10px;
	padding: 3px;
	z-index: 4;
}

.preview-drawer .post-preview {
	background-color: var(--background-color);
	margin: 0;
}

@media screen and (max-width: 1000px) {
	.preview-drawer > div:not(.close-preview-drawer-btn) {
		padding: 2%;
		padding-top: 50px;
	}
}
