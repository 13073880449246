

.custom-select .text {
	font-size: 0.75rem;
	text-rendering: italic;
	font-size: 0.75rem;
	color: #383838;
}

div.dropdown__control {
	background-color: var(--background-color-secondary);
	border: none;
	color: var(--font-color);
	min-width: 200px;
	/* max-width: 300px; */
}

.light-bg div.dropdown__control {
	background-color: var(--background-color);
}

div.dropdown__control.dropdown__control--is-focused {
	outline: 1px solid var(--color-primary)!important;
}

div.dropdown__value-container {
    max-height: 30vh;
    overflow: auto;
}

div.dropdown__indicator,
div.dropdown__clear-indicator {
	cursor: pointer;
}

div.dropdown__input-container {
	color: var(--font-color)!important;
}

div.dropdown__single-value {
	color: var(--font-color);
}

div.dropdown__menu-list {
	padding: 5px;
	z-index: 10;
	border: none
}

div.dropdown__menu {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	border: none
}

div.dropdown__menu,
.dropdown__option {
	background-color: var(--background-color);
	color: var(--font-color);
}

div.dropdown__option {
	border-radius: 6px;
	margin-bottom: 3px;
	padding: 5px 10px;
	line-height:  0.9375rem;
	font-size: 0.875rem;
}

div.dropdown__option--is-selected {
	background-color: var(--color-primary);
}

.dropdown__option:not(.dropdown__option--is-selected):hover,
div.dropdown__option.dropdown__option--is-focused,
div.dropdown__option:focus {
	cursor: pointer;
	background-color: rgba(var(--color-primary-rgb), 0.3);
}

div.dropdown__multi-value {
	background-color: var(--color-primary);
	border-radius: 4px;
}

div.dropdown__multi-value__label {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
	border-radius: 4px;
	max-width: 200px;
}

/*
** -- rounded
*/

.rounded-buttons .custom-select {
	border-radius: 12px!important;
}

 .rounded-buttons div.dropdown__control{
	border-radius: 16px;
}

/*
.rounded-buttons div.dropdown__menu {
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.rounded-buttons div.dropdown__option {
	border-radius: 1000px;
}

.rounded-buttons div.dropdown__multi-value {
	border-radius: 1000px;
	overflow: hidden;
} */
