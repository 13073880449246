.TagLabel {
	display: inline-flex;
	font-size: 0.52rem;
	border-radius: 60px;
	text-align: center;
	margin: 0;
	padding: 0rem 0.4rem;
	transition-duration: 0.3s;
	word-break: initial;
	text-transform: uppercase;
	font-family: 'Poppins-SemiBold';
	background-color: var(--background-color-secondary);
	color: var(--font-color);
	max-width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: keep-all;
}

.theme-dark .TagLabel {
	color: grey;
}

.TagLabel:not(.unstyled) {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.TagLabel.unstyled.selected {
	background-color: var(--color-primary);
	color: var(--color-primary-font-color);
}

.TagLabel.squared {
	width: fit-content;
	border-radius: 5px;
	padding: 0px 5px;
}

.TagLabel.new-tag {
	background-color: rgb(205, 0, 0);
	color: white;
	overflow: initial;
}

.TagLabel.rainbow {
	background-color: red;
	color: rgb(255, 255, 255);
	background-image: linear-gradient(45deg, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 10%, rgba(208, 222, 33, 1) 20%, rgba(79, 220, 74, 1) 30%, rgba(63, 218, 216, 1) 40%, rgba(47, 201, 226, 1) 50%, rgba(28, 127, 238, 1) 60%, rgba(95, 21, 242, 1) 70%, rgba(186, 12, 248, 1) 80%, rgba(251, 7, 217, 1) 90%, rgba(255, 0, 0, 1) 100%);
	animation: rainbow 2s infinite linear;
}

@keyframes rainbow {
	0% { filter: hue-rotate(0deg);}
	100% { filter: hue-rotate(360deg);}
}
