.SitesPage {
	display: flex;
    flex-direction: column;
}

.SitesList {
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	display: table-row;
	margin-left: -12.5px;
	width: calc(100% + 12.5px)!important;
	flex-wrap: wrap;
}

/*
** MAP Cont
*/

.MapCont {
	/* position: absolute; */
	width: 100%;
	height: 100%;
	background-color: var(--background-color);
}

.MapCont .map-cont {
	height: 100%;
}
