.DocsList {
	width: 100%;
}

.btn-favs-docs {
	height: 38px;
}

.search-bar-cont {
	min-width: 200px;
}

.docs-page-inner-cont,
.my-documents-cont {
	overflow: hidden;
	display: flex;
    flex-direction: column;
}

.docs-page-inner-cont .DocsTiles .infinite-scroll-component {
	margin-left: -15px;
	padding: 0;
}
