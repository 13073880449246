.post-preview-cont {
	display: flex;
	height: 100%;
	width: 40%;
	/* min-width: 40%; */
	aspect-ratio: 3/5;
}

.post-preview {
	width: 100%;
	height: 100%;
	background-color: #fff;
	background-color: var(--tiles-bg-color);
	border-radius: 25px;
	display: flex;
	flex-direction: column;
	color: black;
	color: var(--font-color);
	overflow: hidden;
	position: relative;
	padding: 1rem;
	padding-top: 0.75rem;
	padding-bottom: 1.25rem;
	z-index: 1;
}

.edit-drawer .post-preview {
	background-color: var(--background-color);
	height: 100%;
}

.post-preview .moderation-comment-cont {
	background-color: rgb(227, 0, 0);
	color: white;
	border-radius: 6px;
	padding: 5px;
	margin: 10px 0px;
	font-size: 0.875rem;
}

.moderation-comment {
	font-family: "Poppins-Italic";
	margin: 0;
}

.post-preview .post-cont {
	overflow: auto;
	padding-right: 5px;
	margin-right: -10px;
}

.toastui-editor-contents a {
	text-decoration: underline;
	color: #4b96e6;
	color: var(--color-primary) !important;
}

.post-bottom-bar {
	font-size: 0.9375rem;
	padding: 5px 0px;
	padding-top: 10px;
	position: relative;
	top: 15px;
	border-top: 1px solid var(--background-color-darken);
}

.post-preview-cont.no-bottom-bar .post-bottom-bar {
	display: none !important;
}

.post-extend-btn {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.post-preview .author-preview .user-infos {
	font-size: 0.625rem;
	line-height: 0.625rem;
}

/*
** -- Extended
*/

.post-preview-cont.extended {
	position: absolute;
	width: 100%;
	min-width: auto;
	height: 100%;
	backdrop-filter: blur(5px);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #000000a6;
	padding: 0px;
	left: 0%;
	top: 0px;
	z-index: 3;
	overflow: hidden;
}

.post-preview-cont.extended .post-preview {
	height: 100%;
	max-height: unset;
	max-width: 100%;
	overflow-y: auto;
	margin-right: 0;
	border-radius: 0;
	position: relative;
}

.post-preview-cont .post-content {
	padding-bottom: 1rem;
}

.post-preview-cont.extended .post-content {
	overflow: visible;
}

.post-preview-cont.extended .post-extend-btn {
	display: none;
}

.post-preview-cont.extended .extend-image-btn {
	display: none;
}

.post-preview-cont.extended
	.post-images-cont:not(.extended)
	.post-images-slider
	img {
	max-width: 100%;
	max-height: 80vh;
	width: auto;
}

.post-preview-cont.extended .post-linked-docs-cont {
	position: fixed;
}

/*
** -- Extended images
*/

.post-preview.images-extended {
	position: unset;
	z-index: 3;
}

.post-preview.images-extended .post-images-preview {
	margin-left: unset;
}

/*
** -- Markdown viewer
*/

.post-preview .post-text ul > li::before {
	margin-top: 7px;
}

.post-preview .post-text blockquote {
	border-left: 4px solid #e5e5e5;
	border-left: 4px solid var(--color-primary);
	font-family: "Poppins-Italic";
	background-color: var(--background-color);
	border-radius: 4px;
	border-bottom-right-radius: 6px;
	border-top-right-radius: 6px;
	padding: 8px 15px;
}

.post-preview .post-text blockquote p {
	margin: 0;
}

.edit-drawer .post-preview .post-text blockquote {
	background-color: var(--background-color-secondary);
}

.post-preview .post-text pre {
	background-color: #f4f7f8;
	background-color: var(--background-color);
	border-radius: 6px;
	padding: 10px;
}

.post-preview .post-text a {
	color: var(--color-primary) !important;
}

.post-preview .post-text h5 {
	font-size: 1rem;
	font-weight: initial;
}

.post-preview .PostStats .post-text h5 {
	font-size: unset;
	font-weight: initial;
}

/*
** -- FLOATING
*/

.post-preview-cont.floating:not(.extended) {
	position: absolute;
	z-index: 4;
	top: 0px;
	padding-bottom: 0px !important;
	max-width: 100%;
}

.post-preview-cont.floating.float-right {
	right: 0px;
}

.post-preview-cont.floating.float-left {
	left: 0px;
}

.post-preview-cont.floating .post-preview {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	box-shadow: 0px 0px 10px 4px var(--shadow);
	padding-right: 38px;
}

@media (max-width: 700px) {
	.post-preview {
		min-width: unset;
		height: 100%;
		padding: 0;
	}

	.post-preview .post-cont {
		padding-right: 0px;
		width: 100%;
	}

	.post-preview .post-inner-cont {
		padding: 0px 10px;
	}

	.PostPreviewTopBar {
		margin-bottom: 0px;
	}

	.post-bottom-bar {
		top: 0;
		padding: 5px 10px;
		padding-bottom: 10px;
	}

	.post-preview .post-images-slider {
		border-radius: 0px;
		min-height: 25px;
	}

	.PostsPage .post-preview-cont:not(.no-responsive) {
		border-radius: 0px !important;
	}
}

@media (min-width: 1200px) and (max-width: 1399px) {
	.post-preview-cont {
		width: 50%;
		min-width: 50%;
	}
}
